<template>
    <div class="cooperation">
        <div class="header_top">
            <div class="titles">
                <p class="title_one">我要合作</p>
                <p class="english_one">We need to work together</p>
            </div>
        </div>
        <div class="mians">
            <div class="leftboxs">
                <h1>宁波忠柏网络科技有限公司</h1>
                <div class="iconbox">

                    <i class="iconfont icon-ditu-dibiao"></i>

                    <p>地址:宁波市高新区前程国际大厦21楼2110室</p>
                </div>
                <div class="iconbox">
                    <i class="iconfont icon-dianhuatianchong"></i>

                    <p> 电话:0574-87475622</p>
                </div>
                <div class="iconbox">
                    <i class="iconfont icon-youxiang1"></i>

                    <p>公司邮箱:
                        douht@ningbozb.com
                    </p>
                </div>
            </div>
            <div class="rightboxs">
                <div class="address">
                    <img src="@/static/picture/position.png" alt="">
                </div>
            </div>
        </div>
        <Footderbox></Footderbox>
    </div>
</template>
<style>
.cooperation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mians {
    width: 1620px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 50px;
    background-color: #fff;
}

.mians .leftboxs {
    flex: 1;
    padding: 0 2vw;
}

.mians .leftboxs h1 {
    padding: 0 3vw;
    font-size: 35px;
    margin: 40px 0;
}

.mians .leftboxs .iconbox {
    /* padding: 0 3vw; */
    padding-left: 3vw;
    display: flex;
    align-items: center;
    margin: 50px 0;
}

.mians .leftboxs .iconbox i {
    font-size: 55px;
    color: rgb(216, 30, 6);
    margin-right: 20px;
}

.mians .leftboxs .iconbox p {
    font-size: 25px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: bold;
    color: #000;
}

.mians .rightboxs {
    padding-top: 50px;
}

.mians .rightboxs .address {
    margin: 200px 0;
    width: 800px;
    height: 700px;
    overflow: hidden;
}

.mians .rightboxs .address img {
    width: 100%;
    height: 100%;
}


/* .icon {
    width: 50px;
    height: 50px;
    margin: 0 5px;
} */







.top {
    width: 250px;
    height: 80px;
    margin: 40px 0 0 20px;
}

.top p {
    width: 100%;
    height: 80px;
    display: block;
    line-height: 80px;
    font-size: 60px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000;
}

.box_traffic {
    width: 100%;
    height: 936px;
    margin: 0px auto 0;
    border-top: 2px solid rgb(71, 70, 70);
    border-width: 100%;

}

.traffic {
    width: 1620px;
    height: 936px;
    margin: 0 auto;
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: space-between;
}

.traffic .route {
    width: 100%;
    /* height: 140px; */
    margin: 20px auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.traffic .route:nth-child(1) {
    margin-top: 0;
    padding-top: 10px;
}

.traffic .route .icon {
    width: 140px;
    height: 140px;
}

.traffic .route p {
    width: 1230px;
    height: 70px;
    display: block;
    line-height: 70px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #000;
    opacity: 0.67;
    text-align: left;
    white-space: initial;
}

.traffic .bus p:nth-child(1) {
    line-height: 40px;

}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .box_traffic {
        height: 1800px;
    }

    .mians .leftboxs h1 {
        padding: 0;
    }

    .mians .leftboxs .iconbox {
        padding: 0;
        align-items: end;
    }

    .mians .leftboxs .iconbox i {
        font-size: 30px !important;
        margin-right: 1vw !important;
        padding-top: 2px;
    }

    .traffic {
        height: 100%;
        padding: 350px 0;
    }

    .top {
        width: auto;
    }

    .traffic .route {
        align-items: end;
    }

    .traffic .route:nth-of-type(1) {
        margin: 50px 0;
    }

    .traffic .bus p:nth-child(1) {
        line-height: 90px;
        height: 100%;
    }

    .traffic .route p {
        line-height: 100%;
        height: 100%;
    }
}
</style>
<script>

import Footderbox from '@/components/Footderbox/footderbox.vue';
export default {
    name: "cooperation",
    data() {
        return {};
    },
    methods: {},
    mounted() {
        document.documentElement.scrollTop = 820
    },
    components: { Footderbox }

}
</script>
