<template>
    <footer>
        <div class="center" style="margin: 0 auto;">
            <dl>
                <dt><a href="javascript:;" @click="about">关于我们</a></dt>
                <dd><a href="javascript:;">公司介绍</a></dd>
                <dd><a href="javascript:;">联系我们</a></dd>
                <dd><a href="javascript:;">加入我们</a></dd>
            </dl>
            <dl>
                <dt><a href="javascript:;" @click="service">公司服务</a></dt>
                <dd><a href="javascript:;">商店优化投放</a></dd>
                <dd><a href="javascript:;">竞价/信息流推广</a></dd>
                <dd><a href="javascript:;">小程序开发</a></dd>
            </dl>
            <dl>
                <dt><a href="javascript:;" @click="cooperation">我要合作</a></dt>
                <dd><a href="javascript:;">邮箱:douht@ningbozb.com</a></dd>
                <dd><a href="javascript:;">地址:宁波市高新区前程国际大厦21楼2110室</a></dd>
            </dl>
            <!-- <div class="Officialaccount">
                <img src="@/static/picture/QR_1.png" alt="">
                <p>关注微信公众号</p>
            </div> -->
        </div>
        <dd class="dd"><a href="javascript:;" style="color:#fff">地址:宁波市高新区前程国际大厦21楼2110室</a></dd>
        <div class="txt">
            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=5176.19720258.J_9220772140.110.255b76f4SlscHK"
                style=""><img style="position: relative;
    top: 4px;" v-lazy="require('../../static/qie/gonan.png')" alt="">浙公网安备
                33020502001020号</a>
            <a href="https://beian.miit.gov.cn/?spm=5176.19720258.J_9220772140.111.6a2776f4WZpUSU"
                class="ICP">浙ICP备2022037247号-1</a>
        </div>

    </footer>
</template>
<script>
export default {
    methods: {
        about() {
            this.$router.push('/about')
        },
        service() {
            this.$router.push('/service')
        },
        cooperation() {
            this.$router.push('/cooperation')
        }
    },
}
</script>
<style  scoped>
/* 脚部模块 */
footer {
    width: 100%;
    height: 312px;
    background: black;
    margin: 0 auto;
    text-align: center;
}

footer .dd {
    display: none;
}

footer.ICP {
    color: #fff;
    line-height: 60px;
    margin-right: 620px;
}

.center dl dt a {
    color: #fff;
}

.center dl {
    text-align: left;
}

.center dl dd a {
    color: #838384;
}



.center {
    box-sizing: border-box;
    height: 208px;
    border-bottom: 1px solid #B9B9B9;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.center dl {
    line-height: 40px;
    font-size: 20px;
}

.Officialaccount {
    display: flex;
    flex-direction: column;
    position: relative;
    right: -32px;
}

.Officialaccount img {
    width: 127px;
    height: 126px;
    margin-top: 17px;
}

.Officialaccount p {
    color: #fff;
    position: relative;
    bottom: -12px;
    left: -50px;
}

footer .txt {
    float: left;
    margin-left: 220px;
    margin-top: 20px;
    display: flex;
}

footer .txt img {
    width: 20px;
    height: 20px;
}

footer .txt a {
    color: #fff;
    margin-right: 20px;
    font-size: 16px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    footer {
        height: 320px;
        background: black;
        margin: 0 auto;
        text-align: center;
        line-height: 120px;
        overflow: hidden;
    }

    footer .center {
        height: 300px;
        display: none;
    }

    footer .center dl {
        line-height: 70px;
        font-size: 12px;
    }

    footer .dd {
        display: block;
        margin-top: 30px;
    }

    footer .txt a img {
        width: 70px;
        height: 70px;
    }

    footer .txt a {
        text-align: center;
    }

    footer .txt {
        width: 100%;
        float: left;
        display: flex;
        justify-content: center;
        margin: 0;
    }
}
</style>