<template>
  <div class="home">
    <van-sticky>
      <div class="nav">
        <div class="logo">
          <img v-lazy="require('../static/qie/logo.png')" alt="">
        </div>

        <van-tabs v-model="active" @change="Onchange">
          <van-tab title="首页"></van-tab>
          <van-tab title="我们的服务"></van-tab>
          <van-tab title="我们的优势"></van-tab>
          <van-tab title="技术干货"></van-tab>
          <van-tab title="我要合作"></van-tab>
          <van-tab title="关于忠柏"></van-tab>
        </van-tabs>

        <div class="lgin" v-show="annei">
          <button @click="login">登录</button>
          <button @click="zuce">注册</button>
        </div>
        <div class="lgino" v-show="welcome">
          <span>忠柏欢迎您</span>
        </div>
      </div>
    </van-sticky>
    <van-overlay :show="show" @click="show = false">
      <div @click.stop class="login-box">
        <div class="mian">
          <div class="LoginForm">
            <form action="">
              <h2>登录</h2>
              <label for="" class="account">
                <p>手机号：</p><input type="text" placeholder="请输入账号" maxlength="11" v-model="phone" @input="isPhone()">
                <i class="tips" v-show="isTips">* 手机号输入错误，请重新输入</i>
              </label>
              <label for="" class="account">
                <p>密码：</p><input :type="pwd" placeholder="请输入密码" maxlength="11" v-model="passWord">
                <i class="tips" v-show="isTip">* 密码错误，请重新输入</i>
              </label>

              <p class="retrieve">
                <a href="javascript:;" @click="zuce">立即注册</a> <a href="javascript:;" @click="retrieve">找回密码</a>
              </p>

              <label for="" class="bttn">
                <input type="button" class="button" @click="bttn" value="确定" />
              </label>
              <label for="" class="bttns">
                <div class="checkboxs">
                  <van-checkbox v-model="checked"></van-checkbox>
                </div>
                <div class="opop">
                  登录即代表同意用户
                  <a href="javascript:;" @click="clause">服务使用政策</a>和
                  <a href="javascript:;" @click="privacy">隐私政策</a>
                </div>
              </label>
            </form>
          </div>
        </div>
      </div>
    </van-overlay>
    <Side></Side>
    <router-view />
  </div>
</template>
<style lang="css" scoped>
.home {
  width: 100%;
  height: 100%;
}

.van-overlay {
  z-index: 100 !important;
}

.van-overlay .login-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.van-overlay .login-box .mian {
  width: 400px;
  display: flex;
  background-color: #fff;
  border-radius: 25px;
}

.van-overlay .login-box .mian .LoginForm {
  width: 100%;
  text-align: center;
}

.van-overlay .login-box .mian .LoginForm form {
  padding: 30px 40px
}

.van-overlay .login-box .mian .LoginForm form h2 {
  width: 100%;
  display: inline-block;
  height: 35px;
  text-align: center;
  margin-bottom: 30px;
  font-size: 24px;
}

.van-overlay .login-box .mian .LoginForm form .account {
  width: 100%;
  height: 70px;
  margin: 10px auto 10px;
  display: inline-block;
}

.van-overlay .login-box .mian .LoginForm form .account p {
  text-align: left;
  opacity: 0.7;
  width: 100%;
  height: 21px;
  display: block;
  padding-left: 3px;
  font-size: 20px;
}

.van-overlay .login-box .mian .LoginForm form .account input {
  width: 100%;
  height: 40px;
  margin: 7px auto 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  letter-spacing: 2px;
  text-indent: 5px;
}

.van-overlay .login-box .mian .LoginForm form .account i {
  font-size: 14px;
  color: red;
}

.van-overlay .login-box .mian .LoginForm form .retrieve {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.van-overlay .login-box .mian .LoginForm form .bttn {
  margin: 10px auto 30px;
  line-height: 40px;
  border: none;
  display: block;
}

.van-overlay .login-box .mian .LoginForm form .bttn .button {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: rgb(255, 78, 24);
  border: none;
  color: #fff;
  font-size: 18px;
}

.van-overlay .login-box .mian .LoginForm form .bttns {
  display: flex;
  justify-content: center;
}

.van-overlay .login-box .mian .LoginForm form .bttns .checkboxs {
  margin-right: 5px;
}

.login-box .mian .LoginForm .van-checkbox {
  width: 100%;
  display: flex;
  justify-content: center;
}













.back {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 99;
}

.nav {
  display: flex;
  width: 100%;
  height: 80px;
  justify-content: space-around;
  align-items: center;
  z-index: 99;
  position: absolute;
  top: 27px;
  transition: .5s all;
}

.nav .logo {
  text-align: center;
}

.nav .logo img {
  padding-top: 3px;
  box-sizing: border-box;
  width: 90px;
  height: 90px;
  line-height: 80px;
  text-align: center;
}



.lgin button {
  width: 80px;
  height: 40px;
  line-height: 40px;
}

.lgin button:nth-child(1) {
  font-size: 17px;
  border: 2px solid #fff;
  border-radius: 40px;
  background: rgba(255, 0, 0, 0);
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  margin-right: 10px;
}

.lgin button:nth-child(2) {
  background: red;
  border-radius: 40px;
  color: #fff;
  font-size: 17px;
  border: 0;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.lgino span {
  display: inline-block;
  font-size: 25px;
  line-height: 40px;
  color: #fff;
  border-radius: 25px;
  width: 100%;
}

.lgino {
  padding: 0 20px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  text-align: center;
  background: linear-gradient(to right, #F10F00, #fc4503, #fa8b40);
}

:deep(.van-tabs) {
  width: 1500px !important;
}

:deep(.van-tabs--line .van-tabs__wrap) {
  line-height: normal !important;
}

:deep(.van-tabs__nav--line.van-tabs__nav--complete) {
  padding-right: 8px !important;
  width: 100% !important;
  padding-left: 8px !important;
  background: none !important;
}

:deep(.van-sticky--fixed .van-tab span) {
  color: #505050;
}

:deep(.van-sticky--fixed .nav) {
  top: 0 !important;
  transition: .5s all;
  box-shadow: 0 0px 10px 0px #5a5a5a;
  background: #fff
}

:deep(.van-sticky--fixed .nav .lgin button:nth-of-type(1)) {
  border-color: #6d6c6c;
  color: #6d6c6c;
}

:deep(.van-sticky--fixed .van-tab--active span) {
  color: #ee0a24;
}

:deep(.van-tabs__wrap--scrollable .van-tab) {
  font-size: 25px !important;
  color: #fff !important;
  font-weight: 600 !important;
}


:deep(.van-tabs__nav--line) {
  box-sizing: border-box !important;
  padding-bottom: 0 !important;
}

:deep(.van-tabs .van-tab--active) {
  color: #ee0a24 !important;
}

:deep(.van-tabs__line) {
  width: 146px !important;
  height: 4px !important;
  background: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
  .nav .logo {
    margin: 0 10px 0;
  }

  .nav {}


  .bttns {
    display: flex;
    align-items: stretch;
  }

  .lgino span {
    display: inline-block;
    font-size: 25px;
    line-height: 40px;
    color: #fff;
    border-radius: 25px;
    width: 100%;
  }

  .lgino {
    margin-top: 10px !important;
    margin-left: 40px;
    min-width: 400px;
    padding: 0 20px;
    height: 80px;
    line-height: 80px;
    border-radius: 20px;
    text-align: center;
    background: linear-gradient(to right, #F10F00, #fc4503, #fa8b40);
  }

  .lgin {
    min-width: 234px;
    padding: 0 20px;
    height: 80px;
    line-height: 80px;
    width: 400px;
    text-align: center;
    margin-left: 20px;
  }

  .lgin button {
    width: 150px;
    height: 100%;
    line-height: 100%;
    border: none !important;
    background: none !important;
    color: rgb(255, 94, 94) !important;
  }

  .login-box {
    margin: 50% auto;
    position: relative;
    width: 64%;
    height: 1600px;
    border-radius: 80px;
  }

  .login-box .mian {
    display: flex;
    background-color: #fff;
    width: 100% !important;
  }

  .login-box .mian .introduc .blurb {
    height: 100%;
    /* line-height: 480px; */
    width: 30%;
  }

  .login-box .mian .introduc .blurb li {
    /* height: 180px; */
    /* line-height: 180px; */
    display: none;
  }

  .login-box .mian .LoginForm {
    height: 1600px;
    width: 100%;
  }

  .login-box .mian .LoginForm form {
    height: 100%;
  }

  .login-box .mian .LoginForm .account {
    width: 70%;
    height: auto;
    margin: 40px auto;
  }

  .login-box .mian .LoginForm label p {
    height: auto;
  }

  .login-box .mian .LoginForm .account input {
    height: 150px;
  }

  .login-box .mian .LoginForm .retrieve {
    margin: 20px auto;
    height: auto;
    display: flex;
    justify-content: space-between;
    width: 69%;
  }

  .login-box .mian .LoginForm .bttn {
    height: auto;
    margin: 0 0 0 400px;

  }

  .login-box .mian .LoginForm .bttn .button {
    height: 100px;
    width: 450px;
    line-height: 100px;
  }

  .login-box .mian .LoginForm .proposal {
    width: auto;
    margin-top: 50px;
    line-height: 900px;
  }

  .login-box .mian .LoginForm .proposal .van-checkbox {
    padding: 0 80px;
  }


}
</style>
<script>
import Side from '@/components/Side/index.vue';
import { Login } from '@/unitle/api';
import { Toast } from 'vant';
export default {
  name: 'HomeView',
  data() {
    return {
      show: false,
      active: 0,
      annei: true,
      welcome: false,
      phone: '', //电话号码
      passWord: '', //密码
      isTips: false, //电话错误
      isTip: false, //密码错误
      checked: false,
      pwd: 'passWord',
      eyes: "iconfont icon-yanjing",
    };
  },

  methods: {
    clause() {
      this.$router.push({ name: 'clause' });
    },
    privacy() {
      this.$router.push({ name: 'privacy' });
    },
    closeMaskTap(event) {
      if (!this.$refs.maskContent.contains(event.target)) {
        this.mask = false;
      }
    },
    ppp() {
      this.log = 0;
    },
    login() {
      this.show = true;
    },
    bttn() {
      let params = {
        phone: this.phone,
        password: this.passWord
      };
      if (this.checked == true) {
        Login(params).then(res => {
          if (res.data.code == 200) {
            localStorage.setItem('token', res.data.data);
            this.log = 0;
            this.annei = false;
            this.welcome = true;
          }
        });
      } else if (this.checked == false) {
        // alert('请阅读服务使用政策以及隐私政策')
        Toast.fail('请阅读服务使用政策以及隐私政策');
      }
    },
    zuce() {
      this.$router.push('/enroll');
    },
    retrieve() {
      this.$router.push('/retrieve');
    },
    isPhone() {
      var reg = /^(13[0-9]|14[5|7]|15[0|1|2|3|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/;
      if (this.phone.length == 11) {
        if (!reg.test(this.phone)) {
          this.isTips = true;
        } else {
        }
      } else {
        this.isTips = false;
      }
    },
    Onchange() {
      if (this.active == 0) {
        this.$router.push({ path: '/' });
      } else if (this.active == 1) {
        this.$router.push({ path: '/service' });
      } else if (this.active == 2) {
        this.$router.push({ path: '/advantage' });
      } else if (this.active == 3) {
        this.$router.push({ path: '/driedfood' });
        // if (localStorage.getItem('token')) { this.$router.push({ path: '/driedfood' }) }
        // else {
        //   this.log = 1;
        // }
      } else if (this.active == 4) {
        this.$router.push({ path: '/cooperation' });
      } else if (this.active == 5) {
        this.$router.push({ path: '/about' });
      }
    },

  },
  components: { Side },
  created() {
    var imgs = document.querySelectorAll("img");  //获取所有的img元素
    window.onscroll = function () {     //定义触发事件
      var showViewH = document.documentElement.clientHeight;  //获取可视区域的高度
      //获取滚动的高度（兼容性写法）
      var scrollH = document.body.scrollTop || document.documentElement.scrollTop;
      for (var i = 0; i < imgs.length; i++) {
        //判断图片到页面的高度与可视高度和滑动高度的大小
        if (imgs[i].offsetTop < showViewH + scrollH) {
          if (imgs[i].getAttribute('src') == '') {
            imgs[i].src = imgs[i].getAttribute('data-set');
          }
        }
      }
    };
    if (window.localStorage.getItem('token')) {
      this.annei = false;
      this.welcome = true;
    }
  },
  mounted() {
    if (this.$route.name == 'home') {
      this.active = 0;
    } else if (this.$route.name == 'service') {
      this.active = 1;
    } else if (this.$route.name == 'advantage') {
      this.active = 2;
    } else if (this.$route.name == 'driedfood' || this.$route.name == 'romm' || this.$route.name == 'information' || this.$route.name == 'report') {
      this.active = 3;
    } else if (this.$route.name == 'cooperation') {
      this.active = 4;
    } else if (this.$route.name == 'about') {
      this.active = 5;
    }
    window.addEventListener('scroll', this.scrollToTop, true);
  },
  updated() {
    if (this.$route.name == 'home') {
      this.active = 0;
    } else if (this.$route.name == 'service') {
      this.active = 1;
    } else if (this.$route.name == 'advantage') {
      this.active = 2;
    } else if (this.$route.name == 'driedfood' || this.$route.name == 'romm' || this.$route.name == 'information' || this.$route.name == 'report') {
      this.active = 3;
    } else if (this.$route.name == 'cooperation') {
      this.active = 4;
    } else if (this.$route.name == 'about') {
      this.active = 5;
    }
  },

  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
};
</script>
