<template>
    <div class="index">
        <router-view />
        <div class="serve">
            <div class="top_header">
                <div class="title_box">
                    <p class="title"> 一家安卓+iOS双端ASO技术服务型营销推广公司</p>
                    <p class="english">An Android iOS dual end ASO technology service marketing company</p>
                </div>
                <div class="contact">
                    <a href="JavaScript:;" @click="getto">
                        立即咨询联系营销方案
                    </a>
                </div>
                <div class="banner-footer">
                    <div class="bb">
                        <img v-lazy="require('../../static/picture/technical.png')" alt="">
                        <div class="name">
                            <p>技术源头</p>
                            <p>自家技术团队+优化团队</p>
                        </div>
                    </div>
                    <div class="bb">
                        <img v-lazy="require('../../static/picture/certification.png')" alt="">
                        <div class="name">
                            <p>安全合规</p>
                            <p>自主风控体系</p>
                        </div>
                    </div>
                    <div class="bb">
                        <img v-lazy="require('../../static/picture/flow.png')" alt="">
                        <div class="name">
                            <p>私有流量</p>
                            <p>自家产品流量</p>
                        </div>
                    </div>
                    <div class="bb">
                        <img v-lazy="require('../../static/picture/hotline.png')" alt="">
                        <div class="name">
                            <p>精细服务</p>
                            <p>针对需求提供精细化服务</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main">
                <div class="servetitle">
                    <p class="solid"></p>
                    <h3>我们的服务</h3>
                </div>
                <div class="Store">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/5.png')" alt="" style="width:100%;height: 100%;">
                    </div>
                    <div class="writing">
                        <h3>商店优化投放</h3>
                        <h5>主流安卓商店，苹果商店广告投放</h5>
                    </div>
                </div>
                <div class="Bidding">
                    <div class="writing">
                        <h3>竞价/信息流推广</h3>
                        <h5>主流搜索引擎、信息流代运营</h5>
                    </div>
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/4.png')" alt="" style="width:100%;height: 100%;">
                    </div>
                </div>
                <div class="Store">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/2.png')" alt="" style="width:100%;height: 100%;">
                    </div>
                    <div class="writing">
                        <h3>网站建设</h3>
                        <h5>品牌网站建设，门户平台建设</h5>
                    </div>
                </div>
                <div class="Bidding">
                    <div class="writing">
                        <h3>小程序开发</h3>
                        <h5>微信小程序，百度小程序开发制作</h5>
                    </div>
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/6.png')" alt="" style="width:100%;height: 100%;">
                    </div>
                </div>
                <div class="Store">
                    <div class="photo">
                        <img v-lazy="require('../../static/qie/3.png')" alt="" style="width:100%;height: 100%;">
                    </div>
                    <div class="writing">
                        <h3>App开发</h3>
                        <h5>专业双端开发团队，助力品牌成长</h5>
                    </div>
                </div>
            </div>
            <div class="advantagebox_top">
                <div class="main">
                    <div class="servetitle">
                        <p class="solid_one"></p>
                        <h3>我们的优势</h3>
                    </div>
                    <div class="advantagebox">
                        <dl>
                            <dt><img v-lazy="require('../../static/picture/Experience.png')" alt="" /></dt>
                            <dd style="font-size:0.2rem">行业经验丰富</dd>
                            <span></span>
                            <dd class="text">团队在技术和营销领域拥有多年互联网营销经验。丰富的行业经验和解决方案有助于营销目标达成。</dd>
                        </dl>
                        <dl>
                            <dt><img v-lazy="require('../../static/picture/Team.png')" alt="" /></dt>
                            <dd style="font-size:0.2rem">服务流程精细</dd>
                            <span></span>
                            <dd class="text">基于行业经验，细分服务执行环节为独特节点流程，方便针对节点考核和优化！</dd>
                        </dl>
                        <dl>
                            <dt><img v-lazy="require('../../static/picture/Workflow.png')" alt="" /></dt>
                            <dd style="font-size:0.2rem">团队精益执行</dd>
                            <span></span>
                            <dd class="text">自建了底层执行优化与技术团队执行环节不外包提升项目执行效率和精益项目管控。</dd>
                        </dl>
                    </div>
                </div>
            </div>
            <div class="Partners">
                <div class="main_box">
                    <div class="servetitle" style="margin:0;position: relative;left: -33px; margin-left: 25px;">
                        <p class="solid_tow"></p>
                        <h3>合作伙伴</h3>
                    </div>
                    <div class="servetitleBox">
                        <div class="serbox">
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/qie2/1.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>华为市场</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_2.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>苹果市场</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_3.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>小米市场</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_4.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>oppo</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_5.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>vivo</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_6.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>华泰期货</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_7.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>南华期货</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_8.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>广发期货</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/sc.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>徽商期货</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/pic_6.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>华泰证券</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/ic.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>中信建投</p>
                                </div>
                            </div>
                            <div class="Partner">
                                <div class="pic">
                                    <img v-lazy="require('../../static/picture/yuan.png')" alt="">
                                </div>
                                <div class="pictext">
                                    <p>中粮期货</p>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="concerning">
                    <div class="leftbox">
                        <img v-lazy="require('../../static/picture/pic_11.png')" alt="">
                    </div>
                    <div class="rightbox">
                        <div class="servetitle_one">
                            <p class="solid_three"></p>
                            <h3>关于忠柏科技</h3>
                        </div>
                        <div class="lefttextbox">
                            <p>
                                忠柏科技是一家安卓+iOS双端ASO技术服务型营销推广公司，长期服务于头部金融公司，深度研究金融市场获客玩法，公司提供和帮助客户进行网站，小程序、app等设计策划等服务外，同时提供专业的aso优化服务以及安卓和ios双端竞价代运营服务,协助客户分析产品,覆盖关键词,精准控量，从而更好的做到品效合一！
                            </p>
                            <div class="btn">
                                <a href="javascript:;" @click="getto">我要合作</a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <Footderbox></Footderbox>

    </div>
</template>
<style scoped>
.index {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
}

/* 背景 */
.index .top_header {
    width: 100%;
    height: 926px;
    background-image: url('http://abc.zhongbaikj.com/guanwang/shouye_20230310094735.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}

.index .title_box {
    width: 100%;
    height: 160px;
    margin: 290px auto 0;

}

.index .title_box .title {
    font-size: 58px;
    font-weight: bold;
    color: #ffffff;
    text-shadow: -1px 1px #bbb,
        -2px 2px #bbb,
        -3px 3px #bbb,
        -4px 4px 3px #0008;
}

.index .title_box .english {
    margin: 50px auto;
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #fff;
    text-shadow: -1px 1px #bbb,
        -2px 2px #bbb,
        -3px 3px 3px #0008;
}

.contact {
    width: 329px;
    height: 73px;
    margin: 100px auto 0;
    background: linear-gradient(to right, #F10F00, #fc4503, #fa8b40);
    overflow: hidden;
    border-radius: 40px;
}

.index .contact a {
    font-size: 22px;
    color: #FFFEFC;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 73px;
    text-align: center;
    line-height: 73px;
}

.index .banner-footer {
    width: 80%;
    height: 80px;
    /* bottom: 23px; */
    margin: 160px auto 0;
    display: flex;
    justify-content: space-evenly;

}

.index .banner-footer .bb {
    width: 25%;
    /* height: 80px; */
    display: flex;
    justify-content: space-evenly;
    border-right: 3px solid #fff;
    min-width: 200px;
}

.index .banner-footer .bb img {
    margin-top: 17px;
    /* float: left; */
    width: 60px;
    height: 55px;
    margin-right: 10px;
}

.index .banner-footer .bb .name {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}


.index .banner-footer .bb:nth-of-type(4) {
    border-right: none;
}

.index .banner-footer .bb p:nth-of-type(1) {
    color: #FFFFFf;
    font-size: 20px;
    text-align: left;
    font-weight: 600;
}

.banner-footer .bb p:nth-of-type(2) {
    color: #FFFFFf;
    font-size: 20px;
    text-align: left;
    font-weight: 600;
}

.serve {
    width: 100%;
    /* overflow: hidden; */
}

.servetitle {
    width: 500px;
    height: 60px;
    margin: 58px 0 35px 115px;
    box-sizing: border-box;
}

.servetitle .solid {
    width: 48px;
    height: 4px;
    /* margin-left: 15px; */
    background: linear-gradient(to right, #F10F00, #FC7B25);
    margin-bottom: 18px;
}

.main {
    width: 1900px;
    margin: auto;
}

.serve .servetitle h3 {
    /* width: 200px; */
    float: left;
    height: 35px;
    line-height: 35px;
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
    /* background-color: #fa8b40; */
}

.serve .Store,
.Bidding {
    width: 100%;
    height: 390px;
    padding: 0 0 0 115px;
    display: flex;
    /* background-color: #fa8b40; */
}

.Bidding {
    margin: -30px 0;
}

.Store .photo,
.Bidding .photo {
    width: 565px;
    height: 380px;
    overflow: hidden;
    margin: auto 0;
    border-radius: 25px;
    /* background-color: #fc4503; */
}

.Store .writing,
.Bidding .writing {
    width: 1100px;
    height: 380px;
    /* background-color: aqua; */
    margin: auto 0;
}

.writing h3 {
    font-size: 65px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
    margin: 81px 0 50px
}

.writing h5 {
    font-size: 35px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
    opacity: 0.67;
}

.advantagebox_top {
    background: #F7F8FA;
    height: 694px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}

.advantagebox_top .servetitle {
    margin: 58px 0px 35px 115px;
}

.advantagebox {
    display: flex;
    justify-content: space-around;
    margin-top: 69px;
    box-sizing: border-box;
    padding: 0 100px;
}

.advantagebox dl {
    width: 400px;
    height: 450px;
    background: #FFFFFF;
    text-align: center;
}

.advantagebox dl img {
    margin: 52px 0 58px 0;
    width: 85px;
    height: 73px;
}

.advantagebox dl span {
    display: block;
    width: 25px;
    height: 4px;
    background: linear-gradient(to right, #F10F00, #fc4503, #fa8b40);
    margin: 31px auto;
}

.advantagebox dl .text {
    margin: 0 12px 42px 20px;
    /* width: 278px; */
    height: 94px;
    text-align: left;
    white-space: initial;
    font-size: 20px;
    /* transform: scale(0.5); */
    font-family: PingFang SC;
    font-weight: bold;
    color: #181819;
    line-height: 29px;
    text-indent: 48px;
    letter-spacing: 3px;
}

.main .solid_one {
    width: 48px;
    height: 4px;
    background: linear-gradient(to right, #F10F00, #FC7B25);
    margin-bottom: 18px;
}

.Partners {
    height: 1200px;
    width: 100%;
    background: #F7F8FA;
    background-repeat: no-repeat;
    background-size: 100% 563px;
    margin-top: 29px;
    margin: 0 auto;
    overflow: hidden;
}

.main_box {
    width: 1633px;
    margin: auto;
}

.main_box .solid_tow {
    width: 48px;
    height: 4px;
    background: linear-gradient(to right, #F10F00, #FC7B25);
    margin-bottom: 18px;
}

/* 
.servetitleBox {
    width: 100%;
    margin: 0 auto;
    height: 100%;
} */

.servetitleBox {
    width: 100%;
    height: 100%;
    margin: 50px auto;
    padding: 0;
}

.servetitleBox .serbox {
    width: 90%;
    margin: auto;
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
}

.servetitleBox .Partner {
    width: 298px;
    height: 99px;
    border: 3px solid #ffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 14px;
}

.servetitleBox .Partner:hover {
    box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.servetitleBox .Partner:nth-of-type(8) .pic img {
    width: 77px;
    height: 70px;
}

.servetitleBox .Partner .pic {
    width: 50%;
    text-align: right;
    padding-right: 10px;
    box-sizing: border-box;
}

.servetitleBox .Partner .pic img {
    width: 77px;
    height: 77px;
}

.servetitleBox .Partner:nth-of-type(1) .pic img {
    height: auto;
}

.servetitleBox .Partner .pictext {
    flex: 1;
    text-align: left;
}

.servetitleBox .Partner .pictext p {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1A1A1A;
}



.servetitleBox .Partner p span {
    display: block;
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #1A1A1A;
}

.concerning {
    width: 100%;
    height: 418px;
    background: #FFFFFF;
    margin: 0px auto 7px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.concerning .leftbox {
    margin-right: 10px;
    /* float: left; */
}

.lefttextbox {
    width: 752px;
    /* background: #fa8b40; */
    padding-top: 20px;
    box-sizing: border-box;
    margin-top: 10px;
    padding-left: 14px;
}

.lefttextbox p {
    text-align: left;
    white-space: initial;
    display: block;
    line-height: 24px;
    width: 753px;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #212121;
    opacity: 0.76;
    letter-spacing: 1px;
    line-height: 35px;
    text-indent: 50px;
    position: relative;
    top: -21px;
}

.rightbox {
    height: 100%;
}

.rightbox h3 {
    text-align: left;
    padding: 0 14px;
    height: 35px;
    line-height: 35px;
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #181819;
}

.solid_three {
    width: 48px;
    height: 4px;
    margin-top: 50px;
    margin-left: 15px;
    background: linear-gradient(to right, #F10F00, #FC7B25);
    margin-bottom: 18px;
}

.btn {
    width: 141px;
    height: 42px;
    background: linear-gradient(to right, #F21C02, #F74F13, #FA651C, #FC7824, #FC7B25);
}

.btn a {
    display: block;
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    line-height: 42px;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    .index .banner-footer {
        margin-bottom: 40px;
    }

    .title_box .english {
        display: none;
    }

    .index .contact {
        width: 700px;
        height: 120px;
    }

    .index .Partners {
        height: 100%;
    }

    .index .contact a {
        font-size: 12px;
        line-height: 120px;
    }

    .index .banner-footer .bb p:nth-of-type(2) {
        display: none;
    }

    .index .advantagebox dl {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        height: 350px;
    }

    .index .advantagebox dl img {
        width: 80px;
        height: 80px;
    }

    .index .advantagebox dl .text {
        display: none;
    }

    .index .advantagebox dl span {
        display: none;
    }

    .index .servetitleBox .Partner {
        width: 31%;
        margin: 16px 16px;
        display: flex;
        justify-content: space-between;
    }

    .index .servetitleBox .Partner p {
        font-size: 6px;
        width: 300px;
    }

    .index .servetitleBox .Partner p span {
        display: none;
    }

    .index .concerning {
        height: 1300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .index .concerning .leftbox img {
        width: 480px;
        height: 380px;
    }

    .index .concerning .lefttextbox {
        height: 100%;
        margin: 0 auto;
        width: 100%;
    }

    .index .lefttextbox p {
        font-size: 12px;
        line-height: 80px;
        width: 100%;
        text-indent: 130px;
    }

    .index .lefttextbox .btn {
        width: 320px;
        height: 80px;
        /* line-height: 80px; */
    }

    .index .lefttextbox .btn a {
        line-height: 80px;
    }

    .index .concerning .rightbox {
        width: 84%;
    }

    .index .concerning .rightbox .servetitle_one {
        position: relative;
        top: -400px;
        left: -40px;
    }
}
</style>

<script>
import Footderbox from '@/components/Footderbox/footderbox.vue';
export default {
    name: 'index',
    data() {
        return {


        };
    },
    // components: { Footderbox },
    methods: {
        getto() {
            this.$router.push('/cooperation');
        }
    },
    mounted() {
        document.documentElement.scrollTop = 0;
    },
    created() {
        var imgs = document.querySelectorAll("img");  //获取所有的img元素
        window.onscroll = function () {     //定义触发事件
            var showViewH = document.documentElement.clientHeight;  //获取可视区域的高度
            //获取滚动的高度（兼容性写法）
            var scrollH = document.body.scrollTop || document.documentElement.scrollTop;
            for (var i = 0; i < imgs.length; i++) {
                //判断图片到页面的高度与可视高度和滑动高度的大小
                if (imgs[i].offsetTop < showViewH + scrollH) {
                    if (imgs[i].getAttribute('src') == '') {
                        imgs[i].src = imgs[i].getAttribute('data-set');
                    }
                }
            }
        };
    },
    components: { Footderbox }

};
</script>